<template>
  <div>
    <Nav />

    <Tittle class="bg-dark">
      <h1 class="fadeInUp"><span></span>Faslearn blog</h1>
    </Tittle>

    <div class="container margin_60_35">
		<div class="row">
			<div class="col-lg-9" >

				<article class="blog" v-for="p in posts" :key="p">
					<div class="row no-gutters" >
						<div class="col-lg-7" >
							<figure>
								<router-link :to="'/blogpost/'+p._id">
								<a href="#">
									<img :src="publicFiles+p.imageFile" v-if="p.imageFile" alt="">
									<img class="img-fluid" src="../../assets/fast_2.png" v-else alt="">
									<div class="preview"><span>Read more</span></div>
								</a>
								</router-link>
							</figure>
						</div>
						<div class="col-lg-5">
							<div class="post_info">
								<small>{{p.createdAt.split('T')[0]}}</small>
								<h3><router-link :to="'/blogpost/'+p._id"><a href="#">{{p.title}}</a></router-link></h3>
								<p v-if="p.contents.length>0" v-html="formatText(p.contents[0].text)"></p>
								<ul>
									<li>
										<div class="thumb"><img src="" alt=""></div> {{p.superAdmin[0].name}} 
									</li>
								</ul>
							</div>
						</div>
					</div>
				</article>

				
				
				
				<!-- <ul class="cats">
					<li v-for="p in posts" :key="p">
						<div class="alignleft" v-if="p.imageFile">
							<a href="#0"><img :src="publicFiles+p.imageFile" alt=""></a>
						</div>
						<small>{{p.createdAt.split('T')[0]}}</small><br>
						<a :href="'/blogpost/'+p._id"> {{p.title.substring(0, 50)}}<span v-if="p.title.toString().length > 30">...</span></a>
					</li>
				</ul> -->
				
				<nav aria-label="...">
					<ul class="pagination pagination-sm">
					<li class="page-item" v-if="page>1" @click="fetchNew(page-1)">
						<a class="page-link" tabindex="-1" >Previous</a>
					</li>
					<li class="page-item" v-for="p in pages" :key="p"><a class="page-link" :class="{activePage: p==page}" @click="fetchNew(p)" >{{p}}</a></li>
					<li class="page-item" v-if="page<numberOfPages" @click="fetchNew(page+1)">
						<a class="page-link">Next </a>
					</li>
					<li class="page-item">
						<a class="page-link" > total record : {{total}}</a>
					</li>
					</ul>
				</nav>
			</div>

			<aside class="col-lg-3">

			<RightSideBar/>

				<!-- <div class="widget">
					<form>
						<div class="form-group">
							<input type="text" name="search" id="search" class="form-control" placeholder="Search...">
						</div>
						<button type="submit" id="submit" class="btn_1 rounded"> Search</button>
					</form>
				</div> -->
				<!-- /widget -->
				<!-- <div class="widget">
					<div class="widget-title">
						<h4>Recent Posts</h4>
					</div>
					<ul class="comments-list">
						<li>
							<div class="alignleft">
								<a href="#0"><img src="http://via.placeholder.com/150x150/ccc/fff/blog-5.jpg" alt=""></a>
							</div>
							<small>11.08.2016</small>
							<h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
						</li>
						<li>
							<div class="alignleft">
								<a href="#0"><img src="http://via.placeholder.com/150x150/ccc/fff/blog-6.jpg" alt=""></a>
							</div>
							<small>11.08.2016</small>
							<h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
						</li>
						<li>
							<div class="alignleft">
								<a href="#0"><img src="http://via.placeholder.com/150x150/ccc/fff/blog-4.jpg" alt=""></a>
							</div>
							<small>11.08.2016</small>
							<h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
						</li>
					</ul>
				</div> -->
				<!-- /widget -->
				<!-- <div class="widget">
					<div class="widget-title">
						<h4>Blog Categories</h4>
					</div>
					<ul class="cats">
						<li><a href="#">Admissions <span>(12)</span></a></li>
						<li><a href="#">News <span>(21)</span></a></li>
						<li><a href="#">Events <span>(44)</span></a></li>
						<li><a href="#">Focus in the lab <span>(31)</span></a></li>
					</ul>
				</div> -->
				<!-- /widget -->
				<!-- <div class="widget">
					<div class="widget-title">
						<h4>Popular Tags</h4>
					</div>
					<div class="tags">
						<a href="#">Information tecnology</a>
						<a href="#">Students</a>
						<a href="#">Community</a>
						<a href="#">Carreers</a>
						<a href="#">Literature</a>
						<a href="#">Seminars</a>
					</div>
				</div> -->
				<!-- /widget -->
			</aside>
			<!-- /aside -->
		</div>
		<!-- /row -->
	</div>
    <!-- /container -->
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/site/Nav.vue";
import RightSideBar from "@/components/site/RightSideBar.vue";
import Tittle from "@/components/site/Tittle.vue";
import Footer from "@/components/site/Footer.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Blog",
  components: {
    Nav,
    RightSideBar,
    Tittle,
    Footer,
  },
  data(){
    return{
      posts: "",
      published: "all",
      limit: 10,
      Loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      reachedFinalUserLimit: false,
      pages : [],
      numberOfPages: 0,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['publicFiles'])
  },
  methods: {
    ...mapActions(['fetchBlogPosts__public']),
    getPages() {
      this.pages = []
      this.numberOfPages = ~~(this.total/this.limit)
      if((this.total/this.limit)>this.numberOfPages){
        this.numberOfPages = this.numberOfPages + 1
      }
      for (let i = 1; i <= this.numberOfPages; i++) {
        this.pages.push(i)
      }
    },
    fetchNew(page) {
      this.page = page;
      this.Loading = true;
      this.fetchBlogPosts__Admin({published: this.published, page: this.page, limit: this.limit})
      .then(res => {
        this.posts = res.data.blogPosts
        this.total = res.data.total
        this.getPages();
        this.Loading = false;
		window.scrollTo(0,0)
      })
    },
	formatText(text) {
		const parser = new DOMParser();
		//const realText = {{p.contents[0].text.substring(0, 50)}}<span v-if="p.contents[0].text.toString().length > 30">...</span>
		const doc = parser.parseFromString(text, 'text/html');
		return doc.body.innerHTML.substring(0, 200) + "...";
	},
  },
  mounted(){
    this.Loading = true;
    this.fetchBlogPosts__public({page: this.page, limit: this.limit})
    .then(res => {
      this.posts = res.data.blogPosts
      this.total = res.data.total
	  this.getPages();
      this.Loading = false;
    })
  }
};
</script>

<style>
  @import url("./assets/css/blog.css");
  @import url("./assets/css/custom.css");
  .activePage{
	color: white;
	background-color: #ef6421;  
  }
</style>