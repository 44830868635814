<template>
  <div class="widget">
    <div class="widget-title">
      <h4>Recent Posts</h4>
    </div>
    <!-- <ul class="comments-list">
      <li>
        <div class="alignleft">
          <a href="#0"
            ><img
              src="http://via.placeholder.com/150x150/ccc/fff/blog-5.jpg"
              alt=""
          /></a>
        </div>
        <small>11.08.2016</small>
        <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
      </li>
    </ul> -->
  
  <div class="widget">
    <!-- <div class="widget-title">
      <h4>Posts you may like</h4>
    </div> -->
    <ul class="cats">
      <li v-for="p in posts" :key="p">
        <div class="alignleft" >
          <a href="#0">
            <img :src="publicFiles+p.imageFile" v-if="p.imageFile" alt="">
            <img src="../../assets/fast_2.png" v-else alt="">
          </a>
        </div>
       
        <small>{{p.createdAt.split('T')[0]}}</small><br>
        <a :href="'/blogpost/'+p._id"> {{p.title.substring(0, 50)}}<span v-if="p.title.toString().length > 30">...</span></a>
      
        
      </li>
    </ul>
  </div>
  <!-- <div class="widget">
    <div class="widget-title">
      <h4>Popular Tags</h4>
    </div>
    <div class="tags">
      <a href="#">Information tecnology</a>
      <a href="#">Students</a>
      <a href="#">Community</a>
      <a href="#">Carreers</a>
      <a href="#">Literature</a>
      <a href="#">Seminars</a>
    </div>
  </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data(){
    return{
      posts: "",
      published: "all",
      limit: 10,
      Loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      reachedFinalUserLimit: false,
      pages : [],
      numberOfPages: 0,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['publicFiles'])
  },
  methods: {
    ...mapActions(['fetchBlogPosts__public']),
  },
  mounted(){
    this.Loading = true;
    this.fetchBlogPosts__public({page: this.page, limit: this.limit})
    .then(res => {
      this.posts = res.data.blogPosts
      this.total = res.data.total
    })
  }
}


</script>

<style>
</style>